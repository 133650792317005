<template>
  <div v-if="src.length === 0" class="d-flex align-center">
<!--    <v-icon disabled class="ma-1">mdi-microphone</v-icon>-->
    <v-btn
      depressed
      outlined
      disabled
      v-bind="sizes.empty"
      class="ma-1"
    ></v-btn>
  </div>
  <div v-else class="d-flex align-center justify-space-between">
<!--    <v-btn-->
<!--      :height="sizes.button.height"-->
<!--      depressed-->
<!--      class="px-3 ma-1 bg-off-white border border-solid border-primary"-->
<!--      @click="toggleControlDisplay"-->
<!--    >-->
<!--      <span>Recording</span>-->
<!--    </v-btn>-->
    <v-card
      v-show="show"
      :height="sizes.slider.height"
      :width="sizes.slider.width"
      flat
      color="#f3f3f3"
      class="ma-1 px-3 rounded"
    >
      <v-slider
        v-model="elapsed"
        color="primary"
        thumb-color="primary"
        track-color="grey"
      ></v-slider>
    </v-card>
    <audio
      v-show="show"
      ref="player"
      style="display: none"
      @play="playing = true"
      @pause="playing = false"
      @timeupdate="updateElapsed()"
    >
      <source :src="src" />
    </audio>
    <v-btn
      v-show="show"
      depressed
      class="primary white--text ma-1"
      min-width="36"
      :width="sizes.button.width"
      :height="sizes.button.height"
      @click="
        () => {
          playing ? pause() : play();
        }
      "
    >
      <v-icon>{{ icons.action }}</v-icon>
    </v-btn>
    <v-btn
      v-if="isRecorded && isPracticeExam"
      v-show="show"
      depressed
      icon
      class="ma-1 btn-delete"
      min-width="36"
      :width="sizes.button.width"
      :height="sizes.button.height"
      @click="$emit(`delete`)"
    >
      <v-icon color="decline">{{ icons.delete }}</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: ""
    },
    isRecorded: {
      type: Boolean,
      default: false
    },
    isPracticeExam: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      show: false,
      playing: false,
      elapsed: 0
    };
  },
  computed: {
    sizes() {
      return {
        button: {
          width: 40,
          height: 32
        },
        slider: {
          width: 184,
          height: 32
        },
        empty: {
          width: 72,
          height: 32
        }
      };
    },
    icons() {
      return {
        action: `mdi-${this.playing ? "pause" : "play-outline"}`,
        delete: "mdi-close"
      };
    }
  },
  watch: {
    elapsed: {
      handler(value) {
        let time = this.$refs.player.currentTime;
        let duration = this.$refs.player.duration;
        let elapsed = 100 * (time / duration);
        if (Math.abs(elapsed - value) > 1) {
          this.$refs.player.currentTime = (value * duration) / 100;
        }
      }
    }
  },
  created() {
    this.show = this.isRecorded;
  },
  methods: {
    async play() {
      await this.$refs.player.play();
    },
    async pause() {
      await this.$refs.player.pause();
    },
    updateElapsed() {
      let time = this.$refs.player.currentTime;
      let duration = this.$refs.player.duration;
      this.elapsed = 100 * (time / duration);
    }
  }
};
</script>

<style lang="scss" scoped>
.btn-delete::before {
  height: 32px;
  width: 40px;
  border-radius: 4px;
}
</style>
