var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-inline-block", attrs: { tabindex: "0" } },
    [
      _c(
        "div",
        {
          staticClass: "d-flex align-center px-2 rounded",
          class: _vm.classes.history.label,
        },
        [
          _c("div", {
            staticClass: "radio rounded",
            class: [
              _vm.classes.history.button,
              _vm.classes.status,
              _vm.classes.readonly,
            ],
            on: { click: _vm.toggle },
          }),
          _c("span", { staticClass: "pa-2" }, [_vm._v(_vm._s(_vm.label))]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }