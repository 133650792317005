var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "py-4 chip-box" },
    [
      _c(
        "v-chip-group",
        { attrs: { column: "" } },
        _vm._l(_vm.filtered, function (option, i) {
          return _c(
            "v-chip",
            {
              key: i,
              staticClass: "ma-1 xl:text-base",
              attrs: { label: "", draggable: !_vm.readonly },
              on: {
                dragstart: function ($event) {
                  return _vm.handlerDragStart($event, option)
                },
              },
            },
            [_vm._v(" " + _vm._s(option.details || option.item_text) + " ")]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }