var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "checkbox rounded clickable ma-2",
      class: [_vm.classes.history, _vm.classes.status, _vm.classes.readonly],
      attrs: { tabindex: "0" },
      on: { click: _vm.toggle },
    },
    [
      _c(
        "v-icon",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.status,
              expression: "status",
            },
          ],
          attrs: { color: "white" },
        },
        [
          _vm._v(
            _vm._s(
              _vm.isHistory && !_vm.answerCorrect ? "mdi-close" : "mdi-check"
            ) + " "
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }