<template>
  <v-row no-gutters justify="center">
    <div id="edit" :style="{ ...styles }">
      <diagram-question
        v-for="(question, index) in questionMap"
        :key="index"
        v-bind="{
          modelAttr,
          type,
          movable,
          readonly,
          ...question
        }"
        v-on="on"
      ></diagram-question>
    </div>
  </v-row>
</template>

<script>
import DiagramQuestion from "./diagram/DiagramQuestion.vue";

export default {
  components: { DiagramQuestion },
  props: {
    src: {
      type: String,
      default: ""
    },
    files: {
      type: Object,
      default: () => ({})
    },
    type: {
      type: String,
      default: ""
    },
    movable: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    questions: {
      type: Array,
      default: () => []
    },
    modelAttr: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      imageWidth: undefined,
      imageHeight: undefined,
      questionMap: {}
    };
  },
  computed: {
    on() {
      return {
        "close-tag": this.remove,
        "update-position": this.modify,
        clear: v => {
          this.$emit("clear", v);
        },
        drop: v => {
          this.$emit("drop", v);
        }
      };
    },
    url() {
      return this.files[this.src] || this.src;
    },
    styles() {
      return {
        height: `${this.imageHeight || 320}px`,
        width: `${this.imageWidth || 180}px`,
        backgroundSize: "cover",
        backgroundImage: `url(${this.url})`,
        position: "relative"
      };
    }
  },
  watch: {
    url: {
      immediate: true,
      handler(value) {
        if (!value) return;
        let img = new Image();
        img.src = value;
        img.onload = () => {
          this.assignDimensions(img.height, img.width);
        };
      }
    },
    questions: {
      immediate: true,
      deep: true,
      handler() {
        this.populateQuestionMap();
      }
    }
  },
  created() {
    this.value = this.modelAttr[this.identifier];
  },
  methods: {
    assignDimensions(height, width) {
      let scaleFactor = 600 / width;
      this.imageHeight = scaleFactor * height;
      this.imageWidth = scaleFactor * width;
    },
    populateQuestionMap() {
      this.questionMap = {};
      this.questions.forEach((question, index) => {
        this.$set(this.questionMap, question.identifier || question.id, {
          ...question,
          identifier:
            question.identifier ?? String((question.order ?? index) + 1)
        });
      });
    },
    add(id) {
      this.$set(this.questionMap, id, {
        identifier: id,
        coordinates: "left: 0, right: 0"
      });
      this.update();
    },
    remove(id) {
      this.$delete(this.questionMap, id);
      this.update();
    },
    modify({ id, pos }) {
      this.$set(this.questionMap[id], "coordinates", pos);
      this.update();
    },
    update() {
      this.$emit("update-question", Object.values(this.questionMap));
    }
  }
};
</script>

<style lang="scss" scoped></style>
