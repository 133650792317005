<template>
  <div style="width: 120px" class="d-inline-block bg-white rounded">
    <v-text-field
      ref="input"
      readonly
      outlined
      dense
      hide-details
      :clearable="!readonly"
      :autofocus="autofocus"
      :value="chip.text"
      @keydown.prevent
      @keyup.prevent
      @paste.prevent
      @dragover.prevent
      @drop="handlerDrop"
      @click:clear.prevent="handlerClear"
      @focus="onFocus()"
    ></v-text-field>
  </div>
</template>

<script>
export default {
  inject: {
    current: {
      default: () => ({})
    },
    isHistory: {
      default: false
    },
    navigated: {
      type: Function,
      default: undefined
    }
  },
  model: {
    prop: "modelAttr",
    event: "modelEvent"
  },
  props: {
    identifier: {
      required: true,
      type: String
    },
    readonly: {
      type: Boolean,
      default: false
    },
    modelAttr: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      chip: {
        text: undefined,
        id: undefined
      }
    };
  },
  computed: {
    autofocus() {
      return this.identifier === this.current?.id;
    }
  },
  watch: {
    "chip.text": {
      immediate: true,
      handler(x) {
        if (this.isHistory) {
          this.chip.text = this.modelAttr[this.identifier];
          return;
        }
        this.$set(this.modelAttr, this.identifier, x);
        this.$emit("modelEvent", this.modelAttr);
      }
    },
    current: {
      deep: true,
      handler(value) {
        if (this.identifier === value.id) {
          this.$refs.input.focus();
        }
      }
    }
  },
  methods: {
    handlerDrop(event) {
      if (this.readonly) return;
      if (this.chip.text?.length > 0) {
        this.$emit("clear", this.chip.id);
      }
      this.chip.text = event.dataTransfer.getData("itemText");
      this.chip.id = event.dataTransfer.getData("itemId");
      this.$emit("drop", this.chip.id);
    },
    handlerClear() {
      this.$emit("clear", this.chip.id);
      this.chip = { text: "", id: undefined };
    },
    onFocus() {
      if (this.navigated !== undefined) {
        this.navigated(this.identifier);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
