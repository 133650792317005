var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "rounded speaking-container",
      attrs: { tabindex: "0" },
      on: {
        focus: function ($event) {
          return _vm.onFocus()
        },
      },
    },
    [
      !_vm.isHistory
        ? _c(
            "v-row",
            { staticClass: "pa-2", attrs: { "no-gutters": "" } },
            [
              _c("speaking-record", {
                attrs: {
                  state: `${_vm.state}`,
                  "record-once": _vm.recordOnce,
                  "is-practice-exam": _vm.isPracticeExam,
                  "exam-type": "type",
                },
                on: {
                  start: function ($event) {
                    return _vm.startRecording()
                  },
                  pause: function ($event) {
                    return _vm.togglePauseResumeRecording()
                  },
                  stop: function ($event) {
                    return _vm.stopRecording()
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      (_vm.audioAvailable && _vm.state === `inactive`) || _vm.isHistory
        ? _c(
            "v-row",
            { staticClass: "pa-2", attrs: { "no-gutters": "" } },
            [
              _c("speaking-playback", {
                attrs: {
                  src: _vm.audioSource,
                  "is-practice-exam": _vm.isPracticeExam,
                  "is-recorded": _vm.isHistory || _vm.audioAvailable,
                  "exam-type": "type",
                },
                on: {
                  delete: function ($event) {
                    return _vm.reset()
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.showPointsCondition
        ? _c(
            "v-row",
            { staticClass: "pa-2", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  staticClass:
                    "py-1 px-3 bg-off-white d-flex align-center xl:text-base lg:text-base md:text-sm sm:text-sm",
                  attrs: { cols: "12" },
                },
                [
                  _c("span", { staticClass: "mr-3" }, [_vm._v("Marks:")]),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-center pa-2 bg-white rounded border border-solid border-default",
                      staticStyle: { height: "32px", width: "96px" },
                    },
                    [
                      _c("v-text-field", {
                        staticStyle: { outline: "none", width: "100%" },
                        attrs: {
                          dense: "",
                          "hide-details": "",
                          readonly: !_vm.isEvaluation,
                          flat: "",
                          solo: "",
                          type: "text",
                        },
                        on: {
                          blur: function ($event) {
                            _vm.$emit("scoreEvent", {
                              [_vm.identifier]: parseInt(_vm.score),
                            })
                          },
                        },
                        model: {
                          value: _vm.score,
                          callback: function ($$v) {
                            _vm.score = $$v
                          },
                          expression: "score",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("span", { staticClass: "ml-3" }, [
                    _vm._v(_vm._s(`/${_vm.maxScore}`)),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }