var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-row", { attrs: { "no-gutters": "", justify: "center" } }, [
    _c(
      "div",
      { style: { ..._vm.styles }, attrs: { id: "edit" } },
      _vm._l(_vm.questionMap, function (question, index) {
        return _c(
          "diagram-question",
          _vm._g(
            _vm._b(
              { key: index },
              "diagram-question",
              {
                modelAttr: _vm.modelAttr,
                type: _vm.type,
                movable: _vm.movable,
                readonly: _vm.readonly,
                ...question,
              },
              false
            ),
            _vm.on
          )
        )
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }