<script>
import TagFib from "./Tags/TagFib.vue";
import TagMatch from "./Tags/TagMatch.vue";
import TagChips from "./Tags/TagChips.vue";
import TagSelect from "./Tags/TagSelect.vue";
import TagCheck from "./Tags/TagCheck.vue";
import TagDiagram from "./Tags/TagDiagram.vue";
import TagSpeak from "./Tags/TagSpeak.vue";
import TagMcq from "./Tags/TagMcq.vue";
import { RenderUtils } from "/global/utils/rendering";

export default {
  components: {
    TagFib,
    TagMatch,
    TagChips,
    TagDiagram,
    TagSelect,
    TagCheck,
    TagSpeak,
    TagMcq
  },
  mixins: [RenderUtils],
  props: {
    isHistory: {
      type: Boolean,
      default: false
    },
    isEvaluation: {
      type: Boolean,
      default: false
    },
    record: {
      type: Object,
      default: () => ({})
    },
    content: {
      type: String,
      default: ""
    },
    questions: {
      type: Array,
      default: () => []
    },
    answers: {
      type: Array,
      default: () => []
    },
    files: {
      type: Object,
      default: () => ({})
    },
    readonly: {
      type: Boolean,
      default: true
    },
    isPracticeExam: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      attrs: {
        files: {},
        modelAttr: {},
        options: [],
        questions: [],
        readonly: this.readonly,
        isPracticeExam: this.isPracticeExam
      },
      on: {
        modelEvent: x => {
          this.attrs.modelAttr = x;
        },
        scoreEvent: x => {
          this.$emit("submit-score", x);
        },
        drop: this.answerOptionSuccess, // required for drag & drop functionality
        clear: this.answerOptionClear // required for clear functionality
      }
    };
  },
  computed: {
    html() {
      let element = document.createElement("div");
      element.classList.add("group-viewer__content");
      element.innerHTML = this.content || "";
      if (this.readonly && !this.isHistory) element.classList.add("inert-all");

      let diagram = element.querySelector("tag-diagram");
      diagram?.parentElement.classList.add("diagram-container");

      return element.outerHTML;
    }
  },
  watch: {
    questions: {
      immediate: true,
      deep: true,
      handler(value) {
        this.$set(this.attrs, "questions", value);
        if (this.isHistory) {
          this.questions?.forEach(question => {
            this.$set(
              this.attrs.modelAttr,
              question.id,
              this.record[question.id]
            );
          });
        }
      }
    },
    answers: {
      immediate: true,
      deep: true,
      handler(value) {
        this.$set(this.attrs, "options", value);
      }
    },
    files: {
      immediate: true,
      deep: true,
      handler(value) {
        this.$set(this.attrs, "files", value);
      }
    },
    "attrs.modelAttr": {
      deep: true,
      immediate: true,
      handler(value) {
        if (this.isHistory) {
          this.questions?.forEach(question => {
            this.$set(
              this.attrs.modelAttr,
              question.id,
              this.record[question.id]
            );
          });
        }
        this.$emit("update", value);
      }
    }
  },
  provide() {
    return {
      isHistory: this.isHistory,
      isEvaluation: this.isEvaluation
    };
  },
  methods: {
    answerOptionSuccess(payload) {
      this.$refs.opt.confirmDropExternal(payload);
    },
    answerOptionClear(payload) {
      this.$refs.opt.confirmClearExternal(payload);
    }
  }
};
</script>

<style lang="scss" scoped>
.group-viewer__content::v-deep {
  p {
    min-height: 16px;
    padding: 4px;
    max-width: 720px;
    word-break: break-all;
  }

  p.diagram-container {
    max-width: revert;
    min-height: 16px;
  }

  li {
    padding: 4px;
  }

  table {
    border-collapse: collapse;

    td,
    th {
      border: 2px solid black;
      min-width: 50px;
      padding: 5px 10px;
    }
  }

  table.match-table {
    border: 1px solid black;

    th {
      min-width: 50px;
      padding: 5px 10px;
      background-color: transparentize($primary, 0.8);
      border: none;
    }

    td {
      border: 1px dotted $primary;
      justify-items: center;
      horiz-align: center;
    }

    p {
      max-width: 300px;
      word-break: break-all;
    }

    tr:focus-within {
      outline: 2px solid $primary;
    }
  }
}

.group-viewer__content.inert-all::v-deep {
  * {
    pointer-events: none;
  }
}
</style>
