var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-inline-block bg-white rounded",
      staticStyle: { width: "120px" },
    },
    [
      _c("v-text-field", {
        ref: "input",
        attrs: {
          readonly: "",
          outlined: "",
          dense: "",
          "hide-details": "",
          clearable: !_vm.readonly,
          autofocus: _vm.autofocus,
          value: _vm.chip.text,
        },
        on: {
          keydown: function ($event) {
            $event.preventDefault()
          },
          keyup: function ($event) {
            $event.preventDefault()
          },
          paste: function ($event) {
            $event.preventDefault()
          },
          dragover: function ($event) {
            $event.preventDefault()
          },
          drop: _vm.handlerDrop,
          "click:clear": function ($event) {
            $event.preventDefault()
            return _vm.handlerClear.apply(null, arguments)
          },
          focus: function ($event) {
            return _vm.onFocus()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }