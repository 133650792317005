<template>
  <div
    tabindex="0"
    class="checkbox rounded clickable ma-2"
    :class="[classes.history, classes.status, classes.readonly]"
    @click="toggle"
  >
    <v-icon v-show="status" color="white"
      >{{ isHistory && !answerCorrect ? "mdi-close" : "mdi-check" }}
    </v-icon>
  </div>
</template>

<script>
export default {
  inject: {
    current: {
      default: () => ({})
    },
    isHistory: {
      default: false
    },
    navigated: {
      type: Function,
      default: undefined
    }
  },
  model: {
    prop: "modelAttr",
    event: "modelEvent"
  },
  props: {
    identifier: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    modelAttr: {
      type: Object,
      default: () => ({})
    },
    questions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      status: false
    };
  },
  computed: {
    answerCorrect() {
      let questionItem = this.questions.find(
        item => item.id === this.identifier
      );
      return questionItem.answers[0].item_text === this.value;
    },
    classes() {
      return {
        history: !this.isHistory
          ? ""
          : this.answerCorrect
          ? "correct"
          : "wrong",
        status: this.status ? "active" : "",
        readonly: this.readonly ? "inert" : ""
      };
    }
  },
  watch: {
    modelAttr: {
      immediate: true,
      deep: true,
      handler(x) {
        this.status = x[this.identifier] === this.value;
      }
    },
    current: {
      deep: true,
      handler(value) {
        if (this.identifier === value.id) {
          if (this.$el?.focus) this.$el?.focus();
        }
      }
    }
  },
  methods: {
    toggle() {
      if (this.navigated !== undefined) {
        this.navigated(this.identifier);
      }
      if (this.isHistory) return;
      this.$set(
        this.modelAttr,
        this.identifier,
        !this.status ? this.value : ""
      );
      this.$emit("modelEvent", this.modelAttr);
    }
  }
};
</script>

<style lang="scss" scoped>
.checkbox {
  height: 24px;
  width: 24px;
  background-color: transparentize(grey, 0.85);
}

.checkbox:hover {
  background-color: transparentize($primary, 0.85);
}

.checkbox:focus {
  border: none;
  outline: none;
}

.active {
  background-color: $primary;
}

.active:hover {
  background-color: $primary;
}

.active.correct {
  background-color: limegreen;
}

.active.wrong {
  background-color: $decline;
}
</style>
