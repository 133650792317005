<template>
  <div v-if="state === `inactive`">
    <v-btn
        v-if="!isPracticeExam"
        :height="sizes.button.height"
        depressed
        :disabled="recordOnce"
        class="px-3 ma-1 primary white--text"
        @click="$emit(`start`)"
    >
      <v-icon>mdi-microphone</v-icon>
      <span>Record</span>
    </v-btn>
    <v-btn
        v-else
        :height="sizes.button.height"
        class="px-3 ma-1 primary white--text"
        depressed
        @click="$emit(`start`)"
    >
      <v-icon>mdi-microphone</v-icon>
      <span>{{ recordOnce ? 'Re-record' : 'Record' }}</span>
    </v-btn>
  </div>
  <div v-else class="d-flex align-center justify-space-between">
    <!--        <v-avatar-->
    <!--          tile-->
    <!--          class="rounded orange ma-1"-->
    <!--          min-width="36"-->
    <!--          :width="sizes.button.width"-->
    <!--          :height="sizes.button.height"-->
    <!--        >-->
    <!--          <v-icon color="white">{{ icons.record }}</v-icon>-->
    <!--        </v-avatar>-->
    <v-btn
      v-if="isPracticeExam"
      depressed
      class="primary white--text ma-1"
      min-width="36"
      :width="sizes.button.width"
      :height="sizes.button.height"
      @click="$emit(`pause`)"
    >
      <v-icon>{{ icons.pause }}</v-icon>
    </v-btn>
    <v-btn
        depressed
        class="decline white--text ma-1"
        min-width="36"
        :width="sizes.button.width"
        :height="sizes.button.height"
        @click="$emit(`stop`)"
    >
      <v-icon>{{ icons.stop }}</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    state: {
      type: String,
      required: true
    },
    recordOnce: {
      type: Boolean,
      default: false
    },
    isPracticeExam: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    sizes() {
      return {
        button: {
          width: 40,
          height: 32
        }
      };
    },
    icons() {
      return {
        record: "mdi-microphone",
        pause: this.isPracticeExam ? `mdi-${this.state === "paused" ? "play-outline" : "pause"}` : ``,
        stop: "mdi-stop"
      };
    }
  }
};
</script>
