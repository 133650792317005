var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.state === `inactive`
    ? _c(
        "div",
        [
          !_vm.isPracticeExam
            ? _c(
                "v-btn",
                {
                  staticClass: "px-3 ma-1 primary white--text",
                  attrs: {
                    height: _vm.sizes.button.height,
                    depressed: "",
                    disabled: _vm.recordOnce,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit(`start`)
                    },
                  },
                },
                [
                  _c("v-icon", [_vm._v("mdi-microphone")]),
                  _c("span", [_vm._v("Record")]),
                ],
                1
              )
            : _c(
                "v-btn",
                {
                  staticClass: "px-3 ma-1 primary white--text",
                  attrs: { height: _vm.sizes.button.height, depressed: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit(`start`)
                    },
                  },
                },
                [
                  _c("v-icon", [_vm._v("mdi-microphone")]),
                  _c("span", [
                    _vm._v(_vm._s(_vm.recordOnce ? "Re-record" : "Record")),
                  ]),
                ],
                1
              ),
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "d-flex align-center justify-space-between" },
        [
          _vm.isPracticeExam
            ? _c(
                "v-btn",
                {
                  staticClass: "primary white--text ma-1",
                  attrs: {
                    depressed: "",
                    "min-width": "36",
                    width: _vm.sizes.button.width,
                    height: _vm.sizes.button.height,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit(`pause`)
                    },
                  },
                },
                [_c("v-icon", [_vm._v(_vm._s(_vm.icons.pause))])],
                1
              )
            : _vm._e(),
          _c(
            "v-btn",
            {
              staticClass: "decline white--text ma-1",
              attrs: {
                depressed: "",
                "min-width": "36",
                width: _vm.sizes.button.width,
                height: _vm.sizes.button.height,
              },
              on: {
                click: function ($event) {
                  return _vm.$emit(`stop`)
                },
              },
            },
            [_c("v-icon", [_vm._v(_vm._s(_vm.icons.stop))])],
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }