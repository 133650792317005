<template>
  <div style="width: 120px" class="d-inline-block bg-white rounded">
    <v-select
      ref="input"
      v-model="value"
      :autofocus="autofocus"
      :items="choices"
      :item-text="itemText"
      :readonly="readonly"
      outlined
      dense
      hide-details
      @focus="onFocus()"
    ></v-select>
  </div>
</template>

<script>
export default {
  inject: {
    current: {
      default: () => ({})
    },
    isHistory: {
      default: false
    },
    navigated: {
      type: Function,
      default: undefined
    }
  },
  model: {
    prop: "modelAttr",
    event: "modelEvent"
  },
  props: {
    identifier: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    modelAttr: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      value: undefined
    };
  },
  computed: {
    itemText() {
      return "item_text" in (this.options?.at(0) || {})
        ? "item_text"
        : "details";
    },
    choices() {
      let elem = [
        ...new Set(
          this.options.map(item => {
            return item[this.itemText];
          })
        )
      ].map(item => ({ [this.itemText]: item }));
      return [
        {
          [this.itemText]: ""
        },
        ...elem
      ];
    },
    autofocus() {
      return this.identifier === this.current?.id;
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(x) {
        if (this.isHistory) {
          this.value = this.modelAttr[this.identifier];
          return;
        }
        this.$set(this.modelAttr, this.identifier, x);
        this.$emit("modelEvent", this.modelAttr);
      }
    },
    current: {
      deep: true,
      handler(value) {
        if (this.identifier === value.id) {
          this.$refs.input.focus();
        }
      }
    }
  },
  methods: {
    onFocus() {
      if (this.navigated !== undefined) {
        this.navigated(this.identifier);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
