var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.movable
    ? _c(
        "div",
        {
          ref: "self",
          staticClass:
            "pa-1 d-flex align-center justify-space-between draggable box",
          style: _vm.styles.position,
          on: {
            mousedown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "left", 37, $event.key, [
                  "Left",
                  "ArrowLeft",
                ])
              )
                return null
              if ("button" in $event && $event.button !== 0) return null
              $event.preventDefault()
              return _vm.handlerDragStart.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "v-avatar",
            {
              staticClass: "white--text mr-2",
              attrs: { color: "primary", size: "21" },
            },
            [_vm._v(_vm._s(_vm.id || _vm.identifier))]
          ),
          _c(
            "v-card",
            {
              staticClass: "pa-0 d-flex align-center justify-end",
              staticStyle: { height: "36px", width: "100px" },
              attrs: { flat: "", color: "white" },
            },
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit(`close-tag`, _vm.identifier)
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _c(
        "div",
        {
          ref: "self",
          staticClass: "pa-1 d-flex align-center justify-space-between box",
          style: _vm.styles.position,
        },
        [
          _c(
            "v-avatar",
            {
              staticClass: "white--text mr-2",
              attrs: { color: "primary", size: "21" },
            },
            [_vm._v(_vm._s(_vm.identifier))]
          ),
          _c(
            _vm.component,
            _vm._b(
              {
                tag: "component",
                staticStyle: { width: "100px" },
                on: {
                  drop: (v) => _vm.$emit(`drop`, v),
                  clear: (v) => _vm.$emit(`clear`, v),
                },
                model: {
                  value: _vm.modelAttr,
                  callback: function ($$v) {
                    _vm.modelAttr = $$v
                  },
                  expression: "modelAttr",
                },
              },
              "component",
              { identifier: _vm.id || _vm.identifier, readonly: _vm.readonly },
              false
            )
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }