<template>
  <div tabindex="0" class="d-inline-block">
    <div
      class="d-flex align-center px-2 rounded"
      :class="classes.history.label"
    >
      <div
        class="radio rounded"
        :class="[classes.history.button, classes.status, classes.readonly]"
        @click="toggle"
      ></div>
      <span class="pa-2">{{ label }}</span>
    </div>
  </div>
</template>

<script>
export default {
  inject: {
    current: {
      default: () => ({})
    },
    isHistory: {
      default: false
    },
    navigated: {
      type: Function,
      default: undefined
    }
  },
  model: {
    prop: "modelAttr",
    event: "modelEvent"
  },
  props: {
    identifier: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    modelAttr: {
      type: Object,
      default: () => ({})
    },
    questions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      status: false
    };
  },
  computed: {
    questionItem() {
      return this.questions.find(item => item.id === this.identifier);
    },
    answerCorrect() {
      let correctSet = new Set(
        this.questionItem.answers[0].item_text.split(",")
      );
      let ansSet = new Set(JSON.parse(this.modelAttr[this.identifier] ?? `[]`));

      let wa = [];
      let ma = [];
      ansSet.forEach(item => {
        if (!correctSet.has(item)) wa.push(item);
      });
      correctSet.forEach(item => {
        if (!ansSet.has(item)) ma.push(item);
      });
      return wa.length === 0 && ma.length === 0;
    },
    optionCorrect() {
      let correctSet = new Set(
        this.questionItem.answers[0].item_text.split(",")
      );
      return correctSet.has(this.value);
    },
    classes() {
      return {
        history: {
          button: !this.isHistory
            ? ""
            : this.answerCorrect
            ? "correct"
            : "wrong",
          label: this.isHistory && this.optionCorrect ? "highlight" : ""
        },
        status: this.status ? "active" : "",
        readonly: this.readonly ? "inert" : ""
      };
    }
  },
  watch: {
    modelAttr: {
      immediate: true,
      deep: true,
      handler(x) {
        let v = JSON.parse(x[this.identifier] ?? "[]");
        this.status = v?.includes(this.value);
      }
    },
    current: {
      deep: true,
      handler(value) {
        if (this.identifier === value.id) {
          if (this.$el?.focus) this.$el?.focus();
        }
      }
    }
  },
  methods: {
    toggle() {
      if (this.navigated !== undefined) {
        this.navigated(this.identifier);
      }
      if (this.isHistory) return;
      let v = JSON.parse(this.modelAttr[this.identifier] ?? `[]`);
      if (!this.status) v.push(this.value);
      else {
        let i = v.indexOf(this.value);
        v.splice(i, 1);
      }
      this.$set(
        this.modelAttr,
        this.identifier,
        v.length === 0 ? undefined : JSON.stringify(v)
      );
      this.$emit("modelEvent", this.modelAttr);
    }
  }
};
</script>

<style lang="scss" scoped>
.radio {
  height: 24px;
  width: 24px;
  border: 1px solid grey;
  background-color: white;
}

.active {
  background-color: $primary;
  border-color: $primary;
}

.active.correct {
  background-color: limegreen;
  border-color: limegreen;
}

.active.wrong {
  background-color: $decline;
  border-color: $decline;
}

.highlight {
  background-color: transparentize(limegreen, 0.8);
}
</style>
