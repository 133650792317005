<template>
  <div style="width: 120px" class="d-inline-block bg-white rounded">
    <v-text-field
      ref="input"
      v-model="value"
      :readonly="readonly"
      :autofocus="autofocus"
      outlined
      dense
      hide-details
      @dragover.prevent
      @focus="onFocus()"
    ></v-text-field>
  </div>
</template>

<script>
export default {
  inject: {
    current: {
      default: () => ({})
    },
    isHistory: {
      default: false
    },
    navigated: {
      type: Function,
      default: undefined
    }
  },
  model: {
    prop: "modelAttr",
    event: "modelEvent"
  },
  props: {
    identifier: {
      required: true,
      type: String
    },
    readonly: {
      type: Boolean,
      default: false
    },
    modelAttr: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      value: undefined
    };
  },
  computed: {
    autofocus() {
      return this.identifier === this.current?.id;
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(x) {
        if (this.isHistory) {
          this.value = this.modelAttr[this.identifier];
          return;
        }
        this.$set(this.modelAttr, this.identifier, x);
        this.$emit("modelEvent", this.modelAttr);
      }
    },
    current: {
      deep: true,
      handler(value) {
        if (this.identifier === value.id) {
          this.$refs.input.focus();
        }
      }
    }
  },
  methods: {
    onFocus() {
      if (this.navigated !== undefined) {
        this.navigated(this.identifier);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
