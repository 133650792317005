export class AudioRecorder {
  constructor() {
    this.audioBlobs = [];
    this.mediaRecorder = undefined;
    this.ongoingStream = undefined;
    this.state = "inactive";
  }

  /** Start recording the audio
   * @returns {Promise} - returns a promise that resolves if audio recording successfully started
   */
  start() {
    if (!navigator.mediaDevices?.getUserMedia)
      return new Promise.reject(
        new Error("Media is not supported by your browser")
      );

    return navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
      this.ongoingStream = stream;
      this.mediaRecorder = new MediaRecorder(stream);
      this.audioBlobs = [];
      this.mediaRecorder.addEventListener("dataavailable", event => {
        this.audioBlobs.push(event.data);
      });
      this.mediaRecorder.start();
      this.state = "recording";
    });
  }

  /** Stop the started audio recording
   * @returns {Promise} - returns a promise that resolves to the audio as a blob file
   */
  stop() {
    return new Promise(resolve => {
      let mimeType = this.mediaRecorder.mimeType;
      this.mediaRecorder.addEventListener("stop", () => {
        let audioBlob = new Blob(this.audioBlobs, { type: mimeType });
        resolve(audioBlob);
      });
      this.mediaRecorder.stop();
      this.ongoingStream.getTracks().forEach(track => track.stop());

      // reset properties
      this.mediaRecorder = undefined;
      this.ongoingStream = undefined;
      this.state = "inactive";
    });
  }

  /** Cancel audio recording*/
  cancel() {
    this.mediaRecorder.stop();
    this.ongoingStream.getTracks().forEach(track => track.stop());
    this.mediaRecorder = undefined;
    this.ongoingStream = undefined;
    this.state = "inactive";
  }

  /** Pause audio recording*/
  pause() {
    return new Promise(() => {
      this.mediaRecorder.addEventListener("pause", () => {
        this.state = "paused";
      });
      this.mediaRecorder.pause();
    });
  }

  /** Resume audio recording*/
  resume() {
    return new Promise(() => {
      this.mediaRecorder.addEventListener("resume", () => {
        this.state = "recording";
      });
      this.mediaRecorder.resume();
    });
  }

  /** Handles errors*/
  errorHandler(error) {
    switch (error.name) {
      case "AbortError": //error from navigator.mediaDevices.getUserMedia
        return "An AbortError has occurred.";
      case "NotAllowedError": //error from navigator.mediaDevices.getUserMedia
        return "A NotAllowedError has occurred. User might have denied permission.";
      case "NotFoundError": //error from navigator.mediaDevices.getUserMedia
        return "A NotFoundError has occurred.";
      case "NotReadableError": //error from navigator.mediaDevices.getUserMedia
        return "A NotReadableError has occurred.";
      case "SecurityError": //error from navigator.mediaDevices.getUserMedia or from the MediaRecorder.start
        return "A SecurityError has occurred.";
      case "TypeError": //error from navigator.mediaDevices.getUserMedia
        return "A TypeError has occurred.";
      case "InvalidStateError": //error from the MediaRecorder.start
        return "An InvalidStateError has occurred.";
      case "UnknownError": //error from the MediaRecorder.start
        return "An UnknownError has occurred.";
      default:
        return `An error occurred with the error name ${error.name}.`;
    }
  }
}
