<template>
  <div
    v-if="movable"
    ref="self"
    class="pa-1 d-flex align-center justify-space-between draggable box"
    :style="styles.position"
    @mousedown.left.prevent="handlerDragStart"
  >
    <v-avatar color="primary" size="21" class="white--text mr-2">{{
      id || identifier
    }}</v-avatar>
    <v-card
      flat
      color="white"
      class="pa-0 d-flex align-center justify-end"
      style="height: 36px; width: 100px"
    >
      <v-btn icon @click="$emit(`close-tag`, identifier)"
        ><v-icon>mdi-close</v-icon></v-btn
      >
    </v-card>
  </div>
  <div
    v-else
    ref="self"
    class="pa-1 d-flex align-center justify-space-between box"
    :style="styles.position"
  >
    <v-avatar color="primary" size="21" class="white--text mr-2">{{
      identifier
    }}</v-avatar>
    <component
      :is="component"
      v-model="modelAttr"
      style="width: 100px"
      v-bind="{ identifier: id || identifier, readonly }"
      @drop="v => $emit(`drop`, v)"
      @clear="v => $emit(`clear`, v)"
    ></component>
  </div>
</template>

<script>
import TagFib from "../TagFib.vue";
import TagMatch from "../TagMatch.vue";

export default {
  components: { TagFib, TagMatch },
  model: {
    prop: "modelAttr",
    event: "modelEvent"
  },
  props: {
    id: {
      type: String
    },
    identifier: {
      type: String
    },
    movable: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    type: {
      type: String
    },
    coordinates: {
      type: String
    },
    modelAttr: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      vector: {
        posX: undefined,
        posY: undefined,
        displaceX: 0.0,
        displaceY: 0.0
      }
    };
  },
  computed: {
    component() {
      return this.type === "fib" ? "tag-fib" : "tag-match";
    },
    styles() {
      return {
        position: this.coordinates
      };
    }
  },
  methods: {
    limits(pos, dimElem, dimParent) {
      return {
        x: pos / dimParent,
        a: 0,
        b: (dimParent - dimElem) / dimParent
      };
    },
    minmax({ x, a = 0, b = 100 }) {
      return Math.max(a, Math.min(x, b));
    },
    handlerDragStart(event) {
      this.vector.posX = event.clientX; // tracks the x position on start of drag
      this.vector.posY = event.clientY; // tracks the y position on start of drag
      document.onmousemove = this.handlerDrag; // function to execute on mouse move
      document.onmouseup = this.handlerDragEnd; // function to execute when mouse button is released

      this.$refs.self.classList.add("dragmode");
    },
    handlerDrag(event) {
      event.preventDefault();
      this.vector.displaceX = this.vector.posX - event.clientX;
      this.vector.displaceY = this.vector.posY - event.clientY;
      this.vector.posX = event.clientX;
      this.vector.posY = event.clientY;

      let [left, top] = this.adjustPostion();

      this.$emit("update-position", {
        id: this.identifier,
        pos: `left: ${left}%; top: ${top}%`
      });
    },
    handlerDragEnd(event) {
      document.onmouseup = null;
      document.onmousemove = null;

      this.$refs.self.classList.remove("dragmode");
    },
    adjustPostion() {
      let x = this.$refs.self.offsetLeft - this.vector.displaceX;
      let y = this.$refs.self.offsetTop - this.vector.displaceY;
      let elementWidth = this.$refs.self.clientWidth;
      let elementHeight = this.$refs.self.clientHeight;

      let containerWidth = this.$refs.self.parentElement.clientWidth;
      let containerHeight = this.$refs.self.parentElement.clientHeight;

      let left =
        this.minmax(this.limits(x, elementWidth, containerWidth)) * 100;
      let top =
        this.minmax(this.limits(y, elementHeight, containerHeight)) * 100;

      return [left.toFixed(2), top.toFixed(2)];
    }
  }
};
</script>

<style lang="scss" scoped>
.draggable {
  cursor: move;
}

.box {
  position: absolute;
  z-index: 1;
}

.dragmode {
  outline: 2px dashed $primary;
}
</style>
