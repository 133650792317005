var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-inline-block bg-white rounded",
      staticStyle: { width: "120px" },
    },
    [
      _c("v-select", {
        ref: "input",
        attrs: {
          autofocus: _vm.autofocus,
          items: _vm.choices,
          "item-text": _vm.itemText,
          readonly: _vm.readonly,
          outlined: "",
          dense: "",
          "hide-details": "",
        },
        on: {
          focus: function ($event) {
            return _vm.onFocus()
          },
        },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }