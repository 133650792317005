<template>
  <div class="py-4 chip-box">
    <v-chip-group column>
      <v-chip
        v-for="(option, i) in filtered"
        :key="i"
        label
        class="ma-1 xl:text-base"
        :draggable="!readonly"
        @dragstart="handlerDragStart($event, option)"
      >
        {{ option.details || option.item_text }}
      </v-chip>
    </v-chip-group>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default: () => []
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      local: [],
      showList: {}
    };
  },
  computed: {
    filtered() {
      return this.local.filter(item => this.showList[item.index]);
    }
  },
  watch: {
    options: {
      immediate: true,
      deep: true,
      handler() {
        this.populateShowList();
      }
    }
  },
  created() {
    this.populateShowList();
  },
  methods: {
    populateShowList() {
      this.local = this.options.map((item, index) => ({ ...item, index }));
      this.local.forEach(item => {
        this.$set(this.showList, item.index, true);
      });
    },
    handlerDragStart(event, item) {
      event.dataTransfer.dropEffect = "move";
      event.dataTransfer.effectAllowed = "move";
      event.dataTransfer.setData("itemId", item.index);
      event.dataTransfer.setData("itemText", item.details || item.item_text);
    },
    confirmDropExternal(index) {
      this.$set(this.showList, index, false);
    },
    confirmClearExternal(index) {
      this.$set(this.showList, index, true);
    }
  }
};
</script>

<style lang="scss" scoped>
.chip-box {
  max-width: 720px;
}
</style>
