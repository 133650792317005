var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.src.length === 0
    ? _c(
        "div",
        { staticClass: "d-flex align-center" },
        [
          _c(
            "v-btn",
            _vm._b(
              {
                staticClass: "ma-1",
                attrs: { depressed: "", outlined: "", disabled: "" },
              },
              "v-btn",
              _vm.sizes.empty,
              false
            )
          ),
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "d-flex align-center justify-space-between" },
        [
          _c(
            "v-card",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.show,
                  expression: "show",
                },
              ],
              staticClass: "ma-1 px-3 rounded",
              attrs: {
                height: _vm.sizes.slider.height,
                width: _vm.sizes.slider.width,
                flat: "",
                color: "#f3f3f3",
              },
            },
            [
              _c("v-slider", {
                attrs: {
                  color: "primary",
                  "thumb-color": "primary",
                  "track-color": "grey",
                },
                model: {
                  value: _vm.elapsed,
                  callback: function ($$v) {
                    _vm.elapsed = $$v
                  },
                  expression: "elapsed",
                },
              }),
            ],
            1
          ),
          _c(
            "audio",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.show,
                  expression: "show",
                },
              ],
              ref: "player",
              staticStyle: { display: "none" },
              on: {
                play: function ($event) {
                  _vm.playing = true
                },
                pause: function ($event) {
                  _vm.playing = false
                },
                timeupdate: function ($event) {
                  return _vm.updateElapsed()
                },
              },
            },
            [_c("source", { attrs: { src: _vm.src } })]
          ),
          _c(
            "v-btn",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.show,
                  expression: "show",
                },
              ],
              staticClass: "primary white--text ma-1",
              attrs: {
                depressed: "",
                "min-width": "36",
                width: _vm.sizes.button.width,
                height: _vm.sizes.button.height,
              },
              on: {
                click: () => {
                  _vm.playing ? _vm.pause() : _vm.play()
                },
              },
            },
            [_c("v-icon", [_vm._v(_vm._s(_vm.icons.action))])],
            1
          ),
          _vm.isRecorded && _vm.isPracticeExam
            ? _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.show,
                      expression: "show",
                    },
                  ],
                  staticClass: "ma-1 btn-delete",
                  attrs: {
                    depressed: "",
                    icon: "",
                    "min-width": "36",
                    width: _vm.sizes.button.width,
                    height: _vm.sizes.button.height,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit(`delete`)
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { color: "decline" } }, [
                    _vm._v(_vm._s(_vm.icons.delete)),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }