<template>
  <div tabindex="0" class="rounded speaking-container" @focus="onFocus()">
    <v-row v-if="!isHistory" no-gutters class="pa-2">
      <speaking-record
        :state="`${state}`"
        :record-once="recordOnce"
        :is-practice-exam="isPracticeExam"
        exam-type="type"
        @start="startRecording()"
        @pause="togglePauseResumeRecording()"
        @stop="stopRecording()"
      ></speaking-record>
    </v-row>
    <v-row
      v-if="(audioAvailable && state === `inactive`) || isHistory"
      no-gutters
      class="pa-2"
    >
      <speaking-playback
        :src="audioSource"
        :is-practice-exam="isPracticeExam"
        :is-recorded="isHistory || audioAvailable"
        exam-type="type"
        @delete="reset()"
      ></speaking-playback>
    </v-row>
    <v-row v-if="showPointsCondition" no-gutters class="pa-2">
      <v-col
        cols="12"
        class="py-1 px-3 bg-off-white d-flex align-center xl:text-base lg:text-base md:text-sm sm:text-sm"
      >
        <span class="mr-3">Marks:</span>
        <div
          style="height: 32px; width: 96px;"
          class="d-flex align-center pa-2 bg-white rounded border border-solid border-default"
        >
          <v-text-field
            v-model="score"
            dense
            hide-details
            :readonly="!isEvaluation"
            flat
            solo
            type="text"
            style="outline: none; width: 100%"
            @blur="$emit('scoreEvent', { [identifier]: parseInt(score) })"
          />
        </div>
        <span class="ml-3">{{ `/${maxScore}` }}</span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SpeakingRecord from "#ecf/question-bank/components/GroupQuestion/Tags/speak/SpeakingRecord.vue";
import SpeakingPlayback from "#ecf/question-bank/components/GroupQuestion/Tags/speak/SpeakingPlayback.vue";
import { AudioRecorder } from "/global/apis/audioutils";

export default {
  components: { SpeakingRecord, SpeakingPlayback },
  inject: {
    current: {
      default: () => ({})
    },
    isHistory: {
      default: false
    },
    isEvaluation: {
      default: false
    },
    navigated: {
      type: Function,
      default: undefined
    }
  },
  model: {
    prop: "modelAttr",
    event: "modelEvent"
  },
  props: {
    identifier: {
      type: String,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    modelAttr: {
      type: Object,
      default: () => ({})
    },
    isPracticeExam: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      recorder: undefined,
      value: undefined,
      score: undefined,
      recordOnce: false
    };
  },
  computed: {
    audioAvailable() {
      return this.value !== undefined;
    },
    audioSource() {
      return this.audioAvailable
        ? this.value?.file_url || URL.createObjectURL(this.value?.file)
        : "";
    },
    mimeType() {
      return this.value?.type;
    },
    state() {
      return this.recorder?.state;
    },
    showPointsCondition() {
      if (this.isEvaluation) return this.audioSource.length > 0;
      else return this.isHistory;
    },
    maxScore() {
      let question = this.$attrs.questions.find(
        item => item.id === this.identifier
      );
      return question.points;
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(x) {
        if (this.isHistory) {
          this.value = this.modelAttr[this.identifier];
          return;
        }
        this.$set(this.modelAttr, this.identifier, x);
        this.$emit("modelEvent", this.modelAttr);
      }
    },
    current: {
      handler(value) {
        if (value.id === this.identifier) {
          // this.$el.scrollIntoView({ behavior: "smooth", block: "center" });
          this.$el.focus();
        }
      }
    }
  },
  created() {
    this.recorder = new AudioRecorder();
    if (this.isEvaluation || this.isHistory) {
      let score = parseInt(this.modelAttr[this.identifier]?.score);
      this.score = !isNaN(score) ? `${score}` : this.isHistory ? "" : "0";
    }
  },
  methods: {
    async startRecording() {
      if (this.state === "recording") return;
      try {
        await this.recorder?.start();
      } catch (e) {
        let message = this.recorder.errorHandler(e);
        this.$root.$emit("alert", [undefined, message]);
      }
    },
    async togglePauseResumeRecording() {
      if (this.isPracticeExam) {
        if (this.state === "recording") await this.recorder.pause();
        if (this.state === "paused") await this.recorder.resume();
      }
    },
    async stopRecording() {
      try {
        let audioBlob = await this.recorder?.stop();
        Object.assign(audioBlob, {
          name: `${this.identifier}.oga`,
          lastModifiedDate: new Date()
        });
        this.value = { file: audioBlob };
        this.recordOnce = true;
      } catch (e) {
        let message = this.recorder.errorHandler(e);
        this.$root.$emit("alert", [undefined, message]);
      }
    },
    reset() {
      this.value = undefined;
    },
    onFocus() {
      this.$el.scrollIntoView({ behavior: "smooth", block: "center" });
      if (this.navigated !== undefined) {
        this.navigated(this.identifier);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.speaking-container:not(:focus) {
  //border: 2px solid white;
}

.speaking-container:focus {
  //border: 2px solid $primary;
  outline: 2px solid $primary;
}

::v-deep .v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
  min-height: 30px;
}

::v-deep
  .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
  > .v-input__control
  > .v-input__slot {
  padding: 0;
}

::v-deep .v-text-field input {
  text-align: right;
}
</style>
