import { render, staticRenderFns } from "./TagCheck.vue?vue&type=template&id=3885c59d&scoped=true"
import script from "./TagCheck.vue?vue&type=script&lang=js"
export * from "./TagCheck.vue?vue&type=script&lang=js"
import style0 from "./TagCheck.vue?vue&type=style&index=0&id=3885c59d&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3885c59d",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/fluent/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3885c59d')) {
      api.createRecord('3885c59d', component.options)
    } else {
      api.reload('3885c59d', component.options)
    }
    module.hot.accept("./TagCheck.vue?vue&type=template&id=3885c59d&scoped=true", function () {
      api.rerender('3885c59d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "portals/teachers-portal/src/modules/question-bank/components/GroupQuestion/Tags/TagCheck.vue"
export default component.exports